import React from "react";
import { graphql } from "gatsby"
import SEO from "../components/SEO";
import Layout from "../layouts/index";
import ContactAudit from "../components/ContactAudit";
import ContactIparagFuggetlen from "../components/ContactIparagFuggetlen";

import Img from "gatsby-image/withIEPolyfill";
import { Link, FormattedMessage } from "gatsby-plugin-intl";

function formSwitch(form) {
	switch (form) {
		case "audit":
			return <ContactAudit />;
		case "fuggetlen":
			return <ContactIparagFuggetlen />;
		default:
			return null;
	}
}

const Project = props => {
	const { title, form, summary, img } = props.data.markdownRemark.frontmatter;
	const location = props.pageContext.language;
	const { html } = props.data.markdownRemark;
	return (
		<Layout bodyClass="page-project" path={props.location.pathname}>
			<SEO title={title} />
			<div className="strip strip-white strip-diagonal">
				<div className="container pt-4 pt-md-10">
					<div className="row justify-content-start">
						<div className="col-12">
							<div className="project project-single">
								<h1 className="title mb-3">{title}</h1>
								{summary ? (
									<div className="project_summary">
										<div className="row">
											<div className="col-md-6">
												<h3><FormattedMessage id="project_summary" /></h3>
												<p>{summary}</p>
											</div>
											<div className="col-md-6">
												{img ? (
													<Img className="project-kep"
														objectFit="cover"
														fluid={img.childImageSharp.fluid} />
												) : null}
											</div>
										</div>
									</div>
								) : null}

								<div className="content" dangerouslySetInnerHTML={{ __html: html }} />
								{formSwitch(form)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        ident
        path
        title
        featured
        summary
        customer
        date(formatString: "DD MMMM YYYY")
        img {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        active
        lang
        desc
        keywords
        icons
        connected
      }
      html
    }
  }
`;

export default Project;